.myorders-view-body {
  background: #fafafa;
  padding: 10px 50px;
  background-color: #fafafa;
}
.css-124iqbp-MuiTableHead-root {
  display: table-header-group;
  background-color: #fff;
  /* z-index: -1; */
}
.download-btn {
  padding: 5px 22px;
  color: #fff;
  background: #e52727;
  border: none;
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", "sans-serif";
  align-self: center;
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s;
}

.download-btn:hover {
  background-color: #333333;
}
.details-orderID {
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  color: #fafafa;
  font-size: 40px;
  font-weight: 500;
}
