.admin-type-dd {
  width: 100%;
  height: 45px;
  border-radius: 3px;
  padding: 5px;
  padding: 10px;
  border: 1px solid #ccc; /* Default border color */
  color: #555; /* Default text color */
  transition: border-color 0.3s ease;
}
.admin-type-dd-error {
  width: 100%;
  height: 45px;
  border-radius: 3px;
  padding: 5px;
  padding: 10px;
  border: 1px solid red; /* Default border color */
  color: #555; /* Default text color */
  transition: border-color 0.3s ease;
}
.admin-type-dd:focus {
  border-color: #aaa8a8;
  box-shadow: 0 0 0 0.2 rem #fafafa; /* Color of border on click/focus */
  outline: none; /* Remove default focus outline */
}

/* Customize appearance of selected option */
.admin-type-dd option:checked {
  background-color: lightblue; /* Background color of selected option */
  color: black; /* Text color of selected option */
}
