.alert-text {
  text-align: right;
  margin: 0 1em;
  color: #999;
  transition: color 0.5s ease;
  font-size: 14px;
  padding: 5px;
  font-family: "Roboto", sans-serif;
  /* cursor: pointer; */
  width: 100%;
}
