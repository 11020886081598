.navbartext {
  text-align: center;
  margin: 0 1em;
  color: #999;
  transition: color 0.5s ease;
  font-size: 14px;
  padding: 5px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.navbartext:hover {
  color: rgb(35, 38, 40);
}
.NBM-Login-text {
  font-size: 13px;
  font-family: "Roboto", sans-serif;
}
.NBM-Login-ACCOUNT {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}
