.footer-hero {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #333333;
  color: #fff;
  padding: 3rem 6rem;
}

.footer-contactus {
  font-size: 20px;
  color: #fff;
  margin-bottom: 26px;
  font-weight: 500;
  font-family: "Roboto", "sans-serif";
}
.footer-description {
  line-height: 22px;
  list-style: none;
  margin: 0;
  font-size: 15px;
  color: #9e9e9e;
  font-weight: 400;
  padding-bottom: 10px;
  border-bottom: 1px solid #474646;
}
.footers-all-sub-details {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.04em;
  margin-bottom: 2px;
  color: #9e9e9e;
}
.footer-all-sub-details {
  cursor: pointer;
  padding: 7px 0;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.04em;
  color: #9e9e9e;
}
.footers-all-sub-details-content {
  color: #fff;
  margin: 0;
  font-weight: 500;
  font-size: 15px;
}
.footer-Search-Box {
  width: auto;
  background-color: #4c4c4c;
  border: 1px solid #4c4c4c;
  padding: 6px;
  font-size: 15px;
  flex-grow: 1;
  border-radius: 2px;
  font-family: "Roboto", sans-serif;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
  border-color: none;
  margin: 0;
}

.footer-Search-Box:focus {
  background-color: #333333;
  border: 1px solid #4c4c4c;
}
@media (max-width: 800px) {
  .footer-hero {
    padding: 1rem 1.5rem;
  }
  .footers-all-sub-details-content {
    color: #fff;
    margin: 0;
    font-weight: 500;
    font-size: 13px;
  }
}
@media (min-width: 600px) {
  .css-qwfiov-MuiGrid-root > .MuiGrid-item {
    padding-left: 0px !important;
  }
  .footers-all-sub-details-content {
    color: #fff;
    margin: 0;
    font-weight: 500;
    font-size: 13px;
  }
}
