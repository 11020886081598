.section-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 3px;
  margin-top: 2em;
}
.header-section {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}
.result-button {
  padding: 10px;
  padding-top: 30px;
  /* margin-left: 150px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.dd-containser {
  /* display: flex;
  flex-direction: column; */
  /* width: 10vw;
  padding: 10px; */
}
.dd-containser {
  /* justify-content: space-between; */
}
.select-sectio {
  min-width: 100px;
  width: 150px;
  border: 1px solid #ccc;
  padding-right: 10px;
  position: relative;
  background-color: #dfdede;
  padding: 6px;
  font-size: 15px;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
}
.select-sectio:focus {
  background-color: #fff;
  border: 1px solid #ccc;
}
*,
::after,
::before {
  z-index: 100;
  position: relative;
}
.HomePage-Content-Desktop-Title-Text {
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
}
.HomePage-Content-mobile-Title-Text {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  text-align: center;
}

.HPC-D-Text-Main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
}
.HPC-Hero {
  position: relative;
  padding: 0 5em;
  padding-top: 1rem;
  z-index: -1;
  background: #fafafa;
}
.wave-and-finder-wrapper {
  background-color: #fff;
}
@media (max-width: 700px) {
  .HPC-Hero {
    padding: 0 30px;
  }
}
@media (max-width: 400px) {
  .HPC-Hero {
    padding: 0 20px;
  }
}
