.success-page-hero {
  width: 100%;
  display: flex;
  height: 130px;
  background-color: #e52727;
  color: #fff;
  border-radius: 0px 0px 70% 10%;
  justify-content: flex-start;
  align-items: center;
}
.success-page-text {
  padding: 40px;
  font-size: 30px;
  font-weight: 600;
  font-family: "Roboto", "sans-serif";
}
.Home-btn{
  margin-top: 2rem;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  padding: 5px 20px;
  font-family: "Roboto", "sans-serif";
  border: none;
  background:  #e52727;
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s;
}
.Home-btn:hover{
  background: #333333;
  
}
/* responsive */
@media (max-width: 1025px) {
  .success-page-text {
    padding: 40px;
    font-size: 30px;
    font-weight: 600;
    font-family: "Roboto", "sans-serif";
  }
  .success-page-hero {
  }
}
@media (max-width: 800px) {
  .success-page-text {
    padding: 30px;
    font-size: 25px;
    font-weight: 600;
    font-family: "Roboto", "sans-serif";
  }
  .success-page-hero {
  }
}

@media (max-width: 600px) {
  .success-page-text {
    padding: 25px;
    font-size: 22px;
    font-weight: 600;
    font-family: "Roboto", "sans-serif";
  }
  .success-page-hero {
  }
}
@media (max-width: 300px) {
  .success-page-text {
    padding: 20px;
    font-size: 15px;
    font-weight: 600;
    font-family: "Roboto", "sans-serif";
  }
  .success-page-hero {
  }
}
:root {
  --red:"#e52727"
  --blue: #6495ed;
  --white: #faf0e6;
}