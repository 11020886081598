.English-span {
  height: 25px;
  width: 25x;
  background-color: #ebebeb;
  padding: 5px;
  font-size: 13px;
}
.English-Text {
  padding: 5px;
  font-size: 13px;
}
.drawer-menu-main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  margin-top: 10px;
}

.drawer-menu-content {
  padding: 0 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.drawer-menu-cancel-icon {
  justify-content: flex-end;
}
.Drawer-menu--items {
  font-size: 10px;
  font-family: "Roboto", sans-serif;
  align-self: center;
}
.buttom-border-div {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}
.buttom-contact {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px soild #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
  background-color: #ebebeb;
}
.Drawer-menu-hero {
  height: "100%";
  padding: "0 10px";
  margin-top: "10px";
  display: "flex";
  flex-direction: "column";
  align-items: "center";
}
