.product-view-body {
  background: #fafafa;
  z-index: -1;
}
.popover-products-details {
  height: auto;
  width: auto;
  overflow: hidden;
}
.product-card-cart {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.product-card-main-div:hover .product-card-cart {
  filter: invert(1);
  opacity: 1;
}
.product-card-svg-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 40px;
  width: 40px;
  transition: background-color 0.3s ease;
  border-radius: 50%; /* Ensure the wrapper-div is a circle */
}
.product-card-main-div:hover .product-card-svg-wrapper {
  background-color: #e52727; /* Change background color on hover */
}
.product-card-side-menu-heart {
  height: 15px;
  width: 15px;
  padding: 2px;
  position: absolute;
  top: 40px;
  right: -50px; /* Initially hide the heart off-screen */
  transform: translateY(-50%);
  transition: right 0.3s ease;
  fill: #ccc;
}
.product-card-side-menu-heart:hover {
  /* background-color: #ccc; */
  fill: #4d4d4d;
}
.product-card-side-menu-box {
  fill: #ccc;
}
.product-card-side-menu-box:hover {
  /* background-color: #ccc; */
  fill: #4d4d4d;
}
.product-card-main-div:hover .product-card-side-menu-heart {
  right: 11px;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  color: #333;
  text-decoration: none;
  padding: 8px 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.pagination a :hover {
  background-color: #b7b7b7;
}

.pagination .active a {
  background-color: #e52727;
  color: #fff;
}

.previous,
.next {
  cursor: pointer;
}

.PopoverProductsDetails {
  width: 100%; /* Set width to 100% */
  max-width: none; /* Ensure no max-width constraint */
  height: 100%; /* Set height to 100% */
}

/* Adjust the Dialog styles */
.Dialog {
  width: 100%; /* Set width to 100% */
  max-width: none; /* Ensure no max-width constraint */
  height: 100%; /* Set height to 100% */
}

/* Adjust the PaperProps styles for the Popover */
.PopoverProductsDetails-paper {
  width: 100%; /* Set width to 100% */
  max-width: none; /* Ensure no max-width constraint */
  height: 100%; /* Set height to 100% */
  margin: 0; /* Remove any margin */
  border-radius: 0; /* Optionally, remove border-radius */
}

.PopoverProductsDetails {
  width: 100%;
  max-width: none;
  height: 100%;
}

.Dialog {
  width: 100%;
  max-width: none;
  height: 100%;
}

.PopoverProductsDetails-paper {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
  border-radius: 0;
}

/* ==================================== */

.pd-wrap {
  margin-top: 3em;
  /* padding: 0px 30px 0px; */
  font-family: "Poppins", sans-serif;
}
.heading-section {
  font-family: "Roboto", "sans-serif";
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: #262626;
}
.sub-heading {
  font-family: "Roboto", "sans-serif";
  font-size: 12px;
  display: block;
  font-weight: 600;
  color: #2e9ca1;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.heading-section h2 {
  font-size: 32px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 15px;
  font-family: "Roboto", "sans-serif";
}
.user-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  min-width: 80px;
  background-size: 100%;
}
.carousel-testimonial .item {
  padding: 30px 10px;
}
.quote {
  position: absolute;
  top: -23px;
  color: #2e9da1;
  font-size: 27px;
}
.name {
  margin-bottom: 0;
  line-height: 14px;
  font-size: 17px;
  font-weight: 500;
}
.position {
  color: #adadad;
  font-size: 14px;
}
.owl-nav button {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  outline: none;
  height: 25px;
}
.owl-nav button svg {
  width: 25px;
  height: 25px;
}
.owl-nav button.owl-prev {
  left: 25px;
}
.owl-nav button.owl-next {
  right: 25px;
}
.owl-nav button span {
  font-size: 45px;
}
.product-thumb .item img {
  height: 100px;
}
.product-name {
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 4px;
}
.product-price-discount {
  font-size: 22px;
  font-weight: 400;
  padding: 10px 0;
  clear: both;
}
.product-price-discount span.line-through {
  text-decoration: line-through;
  margin-left: 10px;
  font-size: 14px;
  vertical-align: middle;
  color: #a5a5a5;
}
.display-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.product-info {
  width: 100%;
}
.reviews-counter {
  font-size: 13px;
}
.reviews-counter span {
  vertical-align: -2px;
}
.rate {
  float: left;
  padding: 0 10px 0 0;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 15px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 21px;
  color: #ccc;
  margin-bottom: 0;
  line-height: 21px;
}
.rate:not(:checked) > label:before {
  content: "\2605";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}
.product-dtl {
  margin: 10px 30px;
}
.product-dtl p {
  font-size: 14px;
  line-height: 24px;
  color: #7a7a7a;
}
.product-dtl .form-control {
  font-size: 15px;
}
.product-dtl label {
  line-height: 16px;
  font-size: 15px;
}
.form-control:focus {
  outline: none;
  box-shadow: none;
}
.product-count {
  margin-top: 15px;
}
.product-count .qtyminus,
.product-count .qtyplus {
  width: 34px;
  height: 34px;
  background: #0a0b0b;
  text-align: center;
  font-size: 19px;
  line-height: 36px;
  color: #fff;
  cursor: pointer;
}
.product-count .qtyminus {
  border-radius: 3px 0 0 3px;
}
.product-count .qtyplus {
  border-radius: 0 3px 3px 0;
}
.product-count .qty {
  width: 60px;
  text-align: center;
}
.round-black-btn {
  width: 210px;
  border-radius: 4px;
  background: #212529;
  color: #fff;
  padding: 7px 10px;
  display: inline-block;
  margin-top: 20px;
  border: solid 2px #212529;
  transition: all 0.5s ease-in-out 0s;
}
.round-black-btn:hover {
  background: transparent;
  color: #212529;
  text-decoration: none;
}

.add-to-cart-btn {
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 7px 25px 7px 10px;
  transition: background-color 0.2s, color 0.2s;
  border-radius: 2px;
  background: #e52727;
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
}
.add-to-cart-btn:hover {
  background: #333333;
  color: #fff;
}
.product-info-tabs {
  margin-top: 25px;
}
.product-info-tabs .nav-tabs {
  border-bottom: 2px solid #d8d8d8;
}
.product-info-tabs .nav-tabs .nav-item {
  margin-bottom: 0;
}
.product-info-tabs .nav-tabs .nav-link {
  border: none;
  border-bottom: 2px solid transparent;
  color: #323232;
}
.product-info-tabs .nav-tabs .nav-item .nav-link:hover {
  border: none;
}
.product-info-tabs .nav-tabs .nav-item.show .nav-link,
.product-info-tabs .nav-tabs .nav-link.active,
.product-info-tabs .nav-tabs .nav-link.active:hover {
  border: none;
  border-bottom: 2px solid #d8d8d8;
  font-weight: bold;
}
.product-info-tabs .tab-content .tab-pane {
  padding: 30px 20px;
  font-size: 15px;
  line-height: 24px;
  color: #7a7a7a;
}
.review-form .form-group {
  clear: both;
}
.mb-20 {
  margin-bottom: 20px;
}

.review-form .rate {
  float: none;
  display: inline-block;
}
.review-heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 6px;
  text-transform: uppercase;
  color: #000;
}
.review-form .form-control {
  font-size: 14px;
}
.review-form input.form-control {
  height: 40px;
}
.review-form textarea.form-control {
  resize: none;
}
.review-form .round-black-btn {
  text-transform: uppercase;
  cursor: pointer;
}
.product-popup-image-style {
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

/* Star Rating Styles */
.star-rating {
  font-size: 24px;
  color: #ccc;
}

.star {
  display: inline-block;
}

.filled {
  color: #ffc700;
}
.reviews-counter {
  font-size: 16px;
  color: #555;
  display: flex;
  align-items: center;
}

.reviews-counter .star-rating {
  margin-right: 5px;
}

.reviews-counter span {
  margin-right: 5px;
}

.stock-status-green {
  display: inline-block;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  background: #bbfcbb73;
}
.stock-status-red {
  display: inline-block;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  background: #ff081536;
}

.check-mark {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  width: 100%;
  height: 80%;
}

.green {
  color: green;
}

.red {
  color: red;
}
.css-6za6r5-MuiStack-root > :not(style) ~ :not(style) {
  margin-left: 0 !important;
}

.css-l14bap-MuiPaper-root-MuiCard-root {
  max-width: none !important;
  width: 100%;
  padding: 5px !important;
}

.popover-cancel-button {
  padding: 10px 20px 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.css-hz1bth-MuiDialog-container {
  align-items: baseline !important;
}
.Product-view-title {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 10px;
  text-align: center;
}
.product-view-filter-section {
  padding: 0.75rem 1rem;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(65, 64, 64, 0.212);
  width: 100%;
  margin-bottom: 2rem;
}
.product-view-filter-section-wapper-1 {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 40px;
}
.product-view-filter-section-wapper-2 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 40px;
}
.product-view-filter-dropdown {
  color: #262626;
  background-color: #ebebeb;
  border-color: #ebebeb;
  padding: 5px;
  margin: 0 3px;
  font-family: "Roboto", "sans-serif";
}
.product-view-page-count-text {
  font-size: 15px;
  font-family: "Roboto", "sans-serif";
  align-self: center;
}
.myorders-view-body {
  background: #fff;
}
.css-124iqbp-MuiTableHead-root {
  display: table-header-group;
  background-color: #fff;
  /* z-index: -1; */
}
.drawer-part-title {
  font-size: 2rem;
  font-weight: 600;
  font-family: "Roboto", "sans-serif";
}
@media (max-width: 850px) {
  .Product-view-title {
    text-align: left;
  }
  .product-dtl {
    margin: 10px 20px;
  }
  .product-popup-image-style {
    max-width: 300px;
    width: 100%;
  }
  .product-images {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 450px) {
  .Product-view-title {
    text-align: left;
  }
  .popover-cancel-button {
    padding: 80px 20px 0px;
  }
  .product-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-dtl {
    margin: 10px 20px;
  }
  .product-popup-image-style {
    width: 100%;
    max-width: 250px;
  }
  .heading-section {
    font-size: 1.5rem;
  }
  .product-name {
    font-size: 0.8rem;
  }
  .product-manufacturer,
  .product-model,
  .product-condition,
  .product-stock {
    font-size: 1rem;
    font-weight: 400;
  }
  .css-12rl710-MuiPaper-root-MuiDialog-paper {
    max-height: none;
  }
  .round-black-btn {
    border-radius: 4px;
    background: #212529;
    color: #fff;
    padding: 3px 15px;
    display: inline-block;
    margin-top: 5px;
    border: solid 2px #212529;
    transition: all 0.5s ease-in-out 0s;
    font-size: 10px;
  }
  .css-1cr37ji-MuiButtonBase-root-MuiButton-root {
    padding: 3px 4px !important;
  }
  .product-view-filter-dropdown {
    display: flex;
    justify-content: space-between;
    padding: 2px !important;
  }
  .product-view-filter-section-wapper-2 {
    justify-content: space-between;
  }
}
