.add-details-header-wrapper {
  width: 100%;
  display: flex;
  height: 100px;
  background-color: #e52727;
  color: #fff;
  border-radius: 0px 0px 70% 10%;
  justify-content: flex-start;
  align-items: center;
}
.Product-view-title-sx {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 40px;
  padding-left: 20px;
  text-align: center;
}
@media (max-width: 850px) {
  .Product-view-title-sx {
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 40px;
    padding-left: 20px;
    text-align: center;
  }
}
@media (max-width: 450px) {
  .Product-view-title-sx {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 40px;
    padding-left: 10px;
    text-align: center;
  }
}
@media (max-width: 300px) {
  .Product-view-title-sx {
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 40px;
    padding-left: 5px;
    text-align: center;
  }
}
