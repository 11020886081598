.Stripe form {
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(234, 42, 42, 0.186),
    0px 2px 5px 0px rgba(194, 48, 48, 0.284),
    0px 1px 1.5px 0px rgba(239, 51, 51, 0.289);
  border-radius: 7px;
  padding: 40px;
  margin: 10px;
}

#payment-message {
  color: rgb(218, 79, 79);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}
.total-amount-text {
  font-family: "Roboto", "sans-serif";
  font-size: 20px;
  font-weight: 500;
}
/* Buttons and links */
.Stripe button {
  background: #ff3333;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(248, 57, 57, 0.456);
  width: 100%;
  font-family: "Roboto", "sans-serif";
}

.Stripe button:hover {
  filter: contrast(115%);
}

.Stripe button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d400;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
.hero-wrapper {
  display: flex;
  flex-direction: column;
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.gif-wrapper {
  height: 500px;
  width: 500px;
  padding: 20px;
}

@media (max-width: 1025px) {
  .gif-wrapper {
    height: 450px;
    width: 450px;
  }
  form {
    width: 60vw;
    min-width: initial;
  }
}
@media (max-width: 800px) {
  .gif-wrapper {
    height: 380px;
    width: 380px;
  }
  form {
    width: 80vw;
    min-width: initial;
    margin-right: 50px;
  }
}

@media (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
  .gif-wrapper {
    height: 350px;
    width: 350px;
  }
}
@media (max-width: 300px) {
  .gif-wrapper {
    height: 100px;
    width: 100px;
  }
}
