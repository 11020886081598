.top-menu-sub-menu {
  display: flex;
  padding: 10px 5px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 55px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  z-index: -1;
}

.top-menu-sub-menu-wrapper {
  display: flex;
  margin: 0 80px;
}
.top-menu-sub-menu-left {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 25px 7px 10px;
  transition: background-color 0.2s, color 0.2s;
  border-radius: 2px;
  background: #e52727;
  cursor: pointer;
  color: #fff;
}
.top-menu-sub-menu-left:hover {
  background: #333333;
  color: #fff;
  fill: #fff;
}
.top-menu-sub-menu-left-active {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 25px 7px 10px;
  transition: background-color 0.2s, color 0.2s;
  border-radius: 3px;
  background: #e52727;
  color: #fff;
  fill: #fff;
  cursor: pointer;
}
.top-menu-sub-menu-svg {
  margin-left: 15px;
}
.top-menu-sub-menu-svg:hover {
  background: #e52727;
  color: #fff;
  fill: rgb(77, 77, 77);
}
.top-menu-sub-menu-left-text {
  font-weight: 500;
  font-size: 15px;
  font-family: "Roboto", "sans-serif";
}
.top-menu-sub-menu-right-text {
  padding: 7px 10px;
  font-weight: 500;
  font-size: 15px;
  font-family: "Roboto", "sans-serif";
  cursor: pointer;
  border-radius: 3px;
}
.top-menu-sub-menu-right-text:hover {
  background-color: #f2f2f2;
}
.top-menu-sub-menu-right {
  padding-left: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.top-menu-border {
  margin-left: 10px;
  border-left: 1px solid #ccc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1025px) {
  .top-menu-sub-menu {
    display: none;
  }
}
