p {
  margin: 0;
  padding: 0;
}

.popover-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popover-title-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
}
.popover-title-text {
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  margin-top: 10px;
}
.popover-title-email {
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #6c757d;
}
.popover-details-links {
  display: block;
  color: inherit;
  font-size: 1.2rem;
  font-family: inherit;
  line-height: inherit;
  padding: 5px 20px;
  border: none;
  width: 100%;
  background: transparent;
  font-weight: 500;
  cursor: pointer;
}
.popover-details-logout {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ebebeb;
}
.css-q2vcq2-MuiCircularProgress-root {
  color: #fff !important;
  width: 25px !important;
  height: 25px !important;
}
.cart-checkout-btn {
  padding: 10px 25px;
  color: #fff;
  background: #e52727;
  border: none;
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto", "sans-serif";
  align-self: center;
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s;
}
.cart-checkout-btn-disable {
  padding: 10px 25px;
  color: #fff;
  background: #de4747ab;
  border: none;
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto", "sans-serif";
  align-self: center;
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s;
}
.cart-checkout-btn:hover {
  background-color: #333333;
}
.error-div {
  padding-top: 3px;
  font-size: 12px;
  color: #e52727;
  font-family: "Roboto", "sans-serif";
}
