.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(0 0 0), rgb(0 0 0 / 50%));
  display: flex;
  justify-content: flex-start; /* Align text to the right */
  align-items: center; /* Vertically center the text */
  padding: 20px; /* Padding around the text */
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left */
}

.text {
  color: white; /* Text color */
  font-size: 18px; /* Font size */
  margin-bottom: 5px; /* Space between text elements */
}
.carousel-text-title {
  padding-left: 80px;
  font-size: 60px;
  color: #ffeb3b;
  letter-spacing: 5px;
  font-family: "Roboto", sans-serif;
}

.carousel-text-description {
  padding-left: 80px;
  font-size: 20px;
  letter-spacing: 2px;
  font-family: "Roboto", sans-serif;
}
.carousel-img {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
  display: inline-block;
  height: 550px;
  max-width: 100%; /* Ensure the container does not exceed the width of its parent */
  overflow: hidden; /* Prevent image overflow */
}

.img-fluid {
  width: 100%; /* Make the image fill its container horizontally */
  height: 600px; /* Maintain the image's aspect ratio */
  display: block; /* Ensure proper spacing */
}

.slick-dots {
  display: none !important;
}

@media (max-width: 700px) {
  .slick-list {
    height: 350px !important;
  }
  .carousel-text-title {
    font-size: 35px;
    color: #ffeb3b;
    letter-spacing: 5px;
    font-family: "Roboto", sans-serif;
  }
  .carousel-text-description {
    padding-left: 7px;
    font-size: 20px;

    font-family: "Roboto", sans-serif;
  }
  .carousel-img {
    margin-top: 20px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;
    display: inline-block;
    height: 350px;
    max-width: 100%; /* Ensure the container does not exceed the width of its parent */
    overflow: hidden; /* Prevent image overflow */
  }
  .img-fluid {
    width: 100%; /* Make the image fill its container horizontally */
    height: 350px; /* Maintain the image's aspect ratio */
    display: block; /* Ensure proper spacing */
  }
}
@media (max-width: 400px) {
  .slick-list {
    height: 250px !important;
  }
  .carousel-text-title {
    font-size: 25px;
    color: #ffeb3b;
    letter-spacing: 5px;
    font-family: "Roboto", sans-serif;
  }
  .carousel-text-description {
    padding-left: 7px;
    font-size: 15px;

    font-family: "Roboto", sans-serif;
  }
  .carousel-img {
    margin-top: 20px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;
    display: inline-block;
    height: 250px;
    max-width: 100%; /* Ensure the container does not exceed the width of its parent */
    overflow: hidden; /* Prevent image overflow */
  }
  .img-fluid {
    width: 100%; /* Make the image fill its container horizontally */
    height: 300px; /* Maintain the image's aspect ratio */
    display: block; /* Ensure proper spacing */
  }
}
