.NBM-Search-Box {
  background-color: #dfdede;
  border: 1px solid #ccc;
  padding: 6px;
  font-size: 15px;
  flex-grow: 1;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
}

.NBM-Search-Box:focus {
  background-color: #fff;
  border: 1px solid #ccc;
}
.NBM-ColorButtonText {
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  padding: 8px !important;
}
.NBM-Div-Hover {
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}
.NBM-Div-Hover:hover {
  background-color: #ccc9c94b;
}
.svg-container {
  width: 200px;
  height: 200px;
}

.svg {
  width: 100%;
  height: 100%;
}

.popover-input-main {
  height: auto;
  width: 300px;
  overflow: hidden;
}

.autosuggest-container {
  position: relative;
  margin-bottom: 20px;
}

.autosuggest-input {
  width: 300px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.autosuggest-list {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 111111;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.suggestion-item:hover {
  background-color: #f1f1f1;
}
.NBM-dropdown {
  background-color: #dfdede;
  border: 1px solid #ccc;
  padding: 6px;
  font-size: 15px;
  width: 100%;
  max-width: 200px;
  min-width: 100px;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
  cursor: pointer;
}
.NBM-dropdown:focus {
  background-color: #fff;
  border: 1px solid #ccc;
}
